import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from "gatsby"

// Components
import IconBed from '../../images/IconBed'
import IconContact from '../../images/IconContact'

// Assets
import logo from '../../images/walkers-cart-lodge-logo.svg'

const Header = () => {

    const { settingsgeneral, settingsmenu } = useStaticQuery(graphql`
      query {
        settingsgeneral: file(relativePath: { eq: "settings/general.md" }) {
          childMarkdownRemark {
            frontmatter {
                settingheaderbtnonetxt
                settingheaderbtnonelnk
                settingheaderbtntwotxt
                settingheaderbtntwolnk
            }
          }
        }
        settingsmenu: file(relativePath: {eq: "settings/menu.md"}) {
            childMarkdownRemark {
              frontmatter {
                menuonetext
                menutwotext
                menuthreetext
                menuone {
                  link
                  title
                  type
                }
                menutwo {
                  link
                  title
                  type
                }
                menuthree {
                  link
                  title
                  type
                }
              }
            }
          }
      }
    `);
    const settings = settingsgeneral.childMarkdownRemark.frontmatter
    const menu = settingsmenu.childMarkdownRemark.frontmatter
    const [click, setClick] = useState(false)
    const navClick = () => setClick(!click)

    const [scroll, setScroll] = useState(false)
    const changeNav = () => {
        if(window.scrollY >= 40) {
            setScroll(true)
        } else {
            setScroll(false)
        }
    }
    useEffect(() => {
        window.addEventListener("scroll", changeNav, {passive: true})
        return () => {
            window.removeEventListener("scroll", changeNav, {passive: true})
        }
    }, [])
    return (
        <>
        <nav className={scroll? "h-16 bg-white bg-opacity-100 z-20 fixed top-0 w-full duration-500 ease-in-out shadow" : "h-20 bg-white bg-opacity-40 z-20 fixed top-0 w-full duration-500 ease-in-out"}>
            <div className="flex justify-between content-center h-full container mx-auto px-6 z-40">
                <div className="w-1/3 flex justify-start items-center text-sm lg:text-base whitespace-nowrap">
                    <div className="hidden md:block z-50">
                        <Link to={settings.settingheaderbtnonelnk} className="inline-block py-2 px-6 bg-template-accent text-white mx-2 font-cursive tracking-wider">{settings.settingheaderbtnonetxt}</Link> 
                    </div>
                    <div className="hidden md:block z-50">
                        <Link to={settings.settingheaderbtntwolnk} className="inline-block py-2 px-6 bg-template-accent text-white mx-2 font-cursive tracking-wider">{settings.settingheaderbtntwotxt}</Link>
                    </div>
                    <div className="md:hidden z-50">
                        <div className="py-1 px-1 bg-template-accent text-white mx-2">
                            <Link to={settings.settingheaderbtnonelnk} aria-label={settings.settingheaderbtnonetxt}>
                                <IconBed className="fill-current" width="26px" height="26px" />
                            </Link>
                        </div>
                    </div>
                    <div className="md:hidden z-50">
                        <div className="py-1 px-1 bg-template-accent text-white mx-2">
                            <Link to={settings.settingheaderbtntwolnk} aria-label={settings.settingheaderbtntwotxt}>
                                <IconContact className="fill-current" width="26px" height="26px" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="w-1/3 flex justify-center">
                    <Link to="/" className="z-50 flex justify-center">
                        <img src={logo} alt="logo" className={scroll? "w-14 transition duration-500 ease-in-out" : click? "w-14 transition duration-500 ease-in-out" : "w-14 transition duration-500 ease-in-out transform translate-y-10 lg:translate-y-24 scale-150 md:scale-200 xl:scale-300 opacity-90"} />
                    </Link>
                </div>
                <div className="w-1/3 flex justify-end items-center z-50">
                    <div className={click? "mx-2 cursor-pointer outline-none hamburger active" : "mx-2 cursor-pointer outline-none hamburger"} onClick={navClick} onKeyDown={navClick} role="button" tabIndex="0" aria-label="Menu">
                        <span className="bar block h-1 bg-template-accent w-7 md:w-8 rounded my-1 md:my-2"></span>
                        <span className="bar block h-1 bg-template-accent w-7 md:w-8 rounded my-1 md:my-2"></span>
                        <span className="bar block h-1 bg-template-accent w-7 md:w-8 rounded my-1 md:my-2"></span>
                    </div>
                </div>
            </div>
            <div className={click? "-mt-20 w-full translate-y-0 z-0 transform transition duration-500 ease-in-out" : "-mt-20 z-0 -translate-y-96 transform transition duration-500 ease-in-out"}>
                <div className="w-full bg-white shadow">
                    <div className="container mx-auto pt-24 font-cursive px-4 max-w-screen-md grid grid-cols-1 sm:grid-cols-3">
                        <div className="hidden sm:block">
                            <div className="bg-white p-0 flex justify-end items-center">
                                {/* Image Option */}
                            </div>
                        </div>
                        <div className="col-span-2">
                            <ul className="flex flex-col sm:flex-row justify-around tracking-wider pb-4 text-right">
                                <li className="flex-1 py-2">
                                    <span className="text-template-accent font-bold md:text-xl lg:text-2xl">{menu.menuonetext}</span>
                                    <ul className="font-normal text-sm md:text-base">
                                        {menu.menuone.map((item, index) => {
                                        return (
                                            !item.link.startsWith("http")?
                                            <li key={index}><Link to={item.link}>{item.title}</Link></li>
                                            :
                                            <li key={index}><a href={item.link} target="_blank" rel="noreferrer">{item.title}</a></li>
                                        )})}
                                    </ul>
                                </li>
                                <li className="flex-1 py-2">
                                <span className="text-template-accent font-bold md:text-xl lg:text-2xl">{menu.menutwotext}</span>
                                    <ul className="font-normal text-sm md:text-base">
                                        {menu.menutwo.map((item, index) => {
                                        return (
                                            !item.link.startsWith("http")?
                                            <li key={index}><Link to={item.link}>{item.title}</Link></li>
                                            :
                                            <li key={index}><a href={item.link} target="_blank" rel="noreferrer">{item.title}</a></li>
                                        )})}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        </>
    )
}

export default Header