import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ strDescription, lang, strTitle, strImage, strAuthor, meta }) {
  const { siteconfigsettings } = useStaticQuery(
    graphql`
      query {
        siteconfigsettings: file(relativePath: {eq: "settings/general.md"}) {
          id
          childMarkdownRemark {
            frontmatter {
              settingsiteorg
              settingsitetitle
              settingsitedescription
              settingsiteurl
              settingsitedefimage {
                publicURL
              }
            }
          }
        }
      }
    `
  )

  const siteConfig = siteconfigsettings.childMarkdownRemark.frontmatter
  const metaDescription = strDescription || siteConfig.settingsitedescription //zz
  const metaTitleTemplate = siteConfig.settingsiteorg //zz
  const metaTitle = strTitle || siteConfig.settingsitetitle //zz
  const siteUrl = siteConfig.settingsiteurl //zz
  const metaImage = strImage || siteConfig.settingsitedefimage.publicURL //zz
  const author = strAuthor || siteConfig.settingsiteorg //zz

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s · `+metaTitleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: siteUrl+metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: siteUrl+metaImage,
        }, 
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
    strDescription: PropTypes.string,
    lang: PropTypes.string,
    strTitle: PropTypes.string,
    strImage: PropTypes.string,
    strAuthor: PropTypes.string,
}

export default Seo