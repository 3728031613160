import React from 'react'
import { navigate } from "gatsby"

// Components
import SDLocalBusiness from "../StructuredData/SDLocalBusiness"
import Header from './Header'
import Footer from './Footer'

const Layout = ({children}) => {
    // Redirect Unsupported Browser (IE)
    const isSSR = typeof window === "undefined"
    let isIE
    !isSSR && ( isIE = /*@cc_on!@*/false || !!document.documentMode )
    isIE && (navigate(`/browser/`))
    return (
        <>
        <SDLocalBusiness />
        <Header />
        {children}
        <Footer />
        </>
    )
}

export default Layout