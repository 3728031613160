import React from 'react'
import { graphql, useStaticQuery, Link } from "gatsby"

// Components

// Assets
import lavendar from '../../images/lavendar-bg.jpg'

const Footer = () => {
    const { settingsgeneral, settingsmenu } = useStaticQuery(graphql`
      query {
        settingsgeneral: file(relativePath: { eq: "settings/general.md" }) {
          childMarkdownRemark {
            frontmatter {
              settingsitetelephone
              settingfooterentel
              settingfootersloganone
              settingfooterslogantwo
              settingsiteorg
              settingfooterimage {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
                publicURL
              }
            }
          }
        }
        settingsmenu: file(relativePath: {eq: "settings/menu.md"}) {
          childMarkdownRemark {
            frontmatter {
              menuonetext
              menutwotext
              menuthreetext
              menuone {
                link
                title
                type
              }
              menutwo {
                link
                title
                type
              }
              menuthree {
                link
                title
                type
              }
            }
          }
        }
      }
    `);
    const settings = settingsgeneral.childMarkdownRemark.frontmatter
    const menu = settingsmenu.childMarkdownRemark.frontmatter

    return (
        <>
        <div className="relative bottom-0 w-full h-96 opacity-20 -mt-96 lavendar-bg" style={{ zIndex: "-1", backgroundImage: `url(${lavendar})`, backgroundRepeat: 'repeat-x', backgroundPosition: 'bottom'}}></div>
        <footer className="w-full mt-8">
        <div className="bg-gray-900 text-white tracking-widest font-cursive" style={{backgroundImage: `url(${settings.settingfooterimage.publicURL})`, backgroundSize: "cover", backgroundPosition: "center"}}>
            <div className="bg-black w-full bg-opacity-80 px-6">
            <div className="container mx-auto grid grid-cols-1 lg:grid-cols-4 gap-12 text-center lg:text-left py-16">
                <div>
                    <div className="text-4xl font-display text-center text-template-accent">{settings.settingfootersloganone}</div>
                    <div className="text-white text-xl font-display text-center">{settings.settingfooterslogantwo}</div>
                    {settings.settingfooterentel === true &&(
                      <div className="text-center text-base pt-3"><a href={`tel:${settings.settingsitetelephone}`}>{settings.settingsitetelephone}</a></div>
                    )}
                </div>
                <div>
                    <div className="font-display uppercase text-sm tracking-widest mb-6 text-template-accent">{menu.menuonetext}</div>
                    {menu.menuone.map((item, index) => {
                      return (
                        !item.link.startsWith("http")?
                        <Link to={item.link} className="block mb-4" key={index}>{item.title}</Link>
                        :
                        <a href={item.link} target="_blank" rel="noreferrer" className="block mb-4" key={index}>{item.title}</a>
                    )})}
                </div>
                <div>
                    <div className="font-display uppercase text-sm tracking-widest mb-6 text-template-accent">{menu.menutwotext}</div>
                    {menu.menutwo.map((item, index) => {
                      return (
                        !item.link.startsWith("http")?
                        <Link to={item.link} className="block mb-4" key={index}>{item.title}</Link>
                        :
                        <a href={item.link} target="_blank" rel="noreferrer" className="block mb-4" key={index}>{item.title}</a>
                    )})}
                </div>
                <div>
                    <div className="font-display uppercase text-sm tracking-widest mb-6 text-template-accent">{menu.menuthreetext}</div>
                    {menu.menuthree.map((item, index) => {
                      return (
                        !item.link.startsWith("http")?
                        <Link to={item.link} className="block mb-4" key={index}>{item.title}</Link>
                        :
                        <a href={item.link} target="_blank" rel="noreferrer" className="block mb-4" key={index}>{item.title}</a>
                    )})}
                </div>
            </div>
            <div className="flex justify-around pb-8 flex-col sm:flex-row">
                <div className="text-sm lg:text-base text-center font-heading font-light tracking-widest text-white">&#169;{(new Date().getFullYear())} {settings.settingsiteorg}</div>
                <div className="text-xs text-center font-heading font-light tracking-widest text-white pt-3 sm:pt-0">
                    <a href="https://www.rokitmedia.co.uk/" aria-label="Rokit Media Web Design">Website Design by Rokit Media</a>
                </div>
            </div>
            </div>
        </div>
        </footer>
        </>
    )
}

export default Footer