import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// JSON-LD

const SDLocalBusiness = () => {

    // https://developers.google.com/search/docs/data-types/local-business

    const { site } = useStaticQuery(
        graphql`
        query {
            site: file(relativePath: {eq: "settings/general.md"}) {
                id
                childMarkdownRemark {
                  frontmatter {
                    defaultUrl: settingsiteurl
                    defaultName: settingsiteorg
                    defaultTitle: settingsitetitle
                    defaultDescription: settingsitedescription
                    defaultAuthor: settingsiteorg
                    telephoneNumber: settingsitetelephone
                    contactURL: settingsiteurl
                    defaultImage: settingsitedefimage {
                        publicURL
                    }
                  }
                }
              }
        }
      `)
    
        const {
            defaultUrl,
            defaultName,
            defaultDescription,
            telephoneNumber,
            contactURL,
            defaultImage,
        } = site.childMarkdownRemark.frontmatter

    const writeData = () => {
        const scriptData = JSON.stringify(
            {
                "@context": "https://schema.org",
                "@type": "Hotel",
                "description": defaultDescription,
                "additionalType": "https://en.wikipedia.org/wiki/Hotel",
                "@id": defaultUrl,
                "name": defaultName,
                "url": defaultUrl,
                "telephone": telephoneNumber,
                "image": defaultUrl+defaultImage.publicURL,
                "logo": defaultUrl+"/images/walkers-cart-lodge-logo.svg",
                "priceRange" : "£400 - £450",
                "address": {
                    "@type": "PostalAddress",
                    "name": defaultName+" Address",
                    "streetAddress": "Walkers Farm House",
                    "addressLocality": "Farnham",
                    "addressRegion": "Hertfordshire",
                    "postalCode": "CM231HY",
                    "addressCountry": "UK",
                    "@id": contactURL
                },
                "geo": {
                    "@type": "GeoCoordinates",
                    "longitude": 0.143347,
                    "latitude": 51.900359,
                    "name": defaultName+" Geo Coordinates",
                },
                "openingHoursSpecification": [
                    {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": [
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday"
                        ],
                        "opens": "09:00",
                        "closes": "05:30",
                        "name": "Opening Hours Mon-Fri",
                    }
                ],
            }
    )
        return(
            scriptData
        )}
    return(
        <>
        <Helmet>
            <script type="application/ld+json">
                {writeData()}
            </script>
        </Helmet>
        </>
    )
}

export default SDLocalBusiness